


export const TitleList = [
    {
        width: "40",
        value: "学校",
        after: true
    },{
        width: "30",
        value: "新增",
        after: true
    },{
        width: "30",
        value: "累计用户",
        after: false
    },
]


export const testList = [
    [
        {
            value: "无",
        }, {
            value: "0",
        }, {
            value: "0",
        }
    ]
]
