import Storage from "@/util/Storage";
import {getSchoolUserCount} from "@/Api/Platform/bascs";
import {filterToArray} from "@/views/PlatformAdmin/Util";

// 获取学校 用户量
export function getSchoolUserNum(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getSchoolUserCount({ userId,token },body).then(res=>{
        return {
            list:filterToArray(res.data,"0","schoolName","newUserCount","userTotal",),
            rawList:res.data
        }
    }))
}
